html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

#mount {
	height: inherit;
	width: inherit;
	background: #FFFFFF;
}

.mobilepush-device-previewer {
	min-width: 800px;
	background-color: #FFFFFF;
	
	#previewer-header {
		background-color: #F3F3F3;
	}
	
	#previewer-container {
		margin-top: 20px;
		text-align: center;
		background-color: #FFFFFF;
		
		iframe {
			width: 292px !important;
			height: 520px !important;
			border: 0;
			margin: 0 auto;
		}

		iframe.landscape {
			width: 520px !important;
			height: 292px !important;
		}

		.iframe-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: rgba(255, 255, 255, 0.8);
			z-index: 1000;
		}

		iframe.meta-flows-preview {
			width: 450px !important;
			height: 800px !important;
			border: 0;
			margin: 0 auto;
		}
	}
}

.push {
	.mobilepush-device-previewer {
		#previewer-container {			
			iframe {
				width: 312px !important;
				height: 624px !important;
			}
		}
	}
}



.whatsapptemplate {
	.mobilepush-device-previewer {
		#previewer-container {
			iframe.landscape {
				width: 540px !important;
				height: 400px !important;
			}
		}
	}
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
}