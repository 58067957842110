.whatsappsession, .whatsapptemplate {
	#orientation-controls {
		display: flex;
	}
	.slds-grid {
		min-height: 44px;
		align-items: center;

		.slds-col:first-child {
			padding-left: 1rem;
		}

		.slds-col:first-child + div {
			text-align: center;
			white-space: nowrap;
		}

		.slds-tooltip-trigger {
			display: inline-block;
			position: relative;

			&:last-child {
				button {
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}

			button {
				width: 3rem;

				.slds-button__icon_stateful {
					height: 1.25rem;
					width: 1.25rem;
				}
			}

			.slds-popover {
				position: absolute;
				top: 45px;
				z-index: 99999;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);

				.slds-popover__body {
					white-space: nowrap;
				}
			}
		}
		
		.combobox-component {
			margin-top: 5px;
			
			.slds-form-element {
				display: inline-block;
				width: 200px;
			}

			.slds-icon_container {
				&.slds-icon-utility-check {
					color: #0070d2;
				}
			}
			
			.slds-combobox__form-element {
				cursor: pointer;
				
				.slds-input {
					text-align: left;
				}
			}
			
			.slds-dropdown {
				.slds-listbox {
					.slds-media__figure {
						margin-top: 2px;
					}
				}
			}
		}
	}
}

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.retry-button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: white;
    color: #0176D3;
    border-radius: 4px;
}

.error-text {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.retry-text {
    color: #3E3E3C;
}
